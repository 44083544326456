exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-styles-js": () => import("./../../../src/pages/styles.js" /* webpackChunkName: "component---src-pages-styles-js" */),
  "component---src-pages-ui-color-js": () => import("./../../../src/pages/ui/color.js" /* webpackChunkName: "component---src-pages-ui-color-js" */),
  "component---src-pages-ui-design-systems-js": () => import("./../../../src/pages/ui/design-systems.js" /* webpackChunkName: "component---src-pages-ui-design-systems-js" */),
  "component---src-pages-ui-fonts-js": () => import("./../../../src/pages/ui/fonts.js" /* webpackChunkName: "component---src-pages-ui-fonts-js" */),
  "component---src-pages-ui-icons-js": () => import("./../../../src/pages/ui/icons.js" /* webpackChunkName: "component---src-pages-ui-icons-js" */),
  "component---src-pages-ux-accessibility-js": () => import("./../../../src/pages/ux/accessibility.js" /* webpackChunkName: "component---src-pages-ux-accessibility-js" */),
  "component---src-pages-ux-templates-js": () => import("./../../../src/pages/ux/templates.js" /* webpackChunkName: "component---src-pages-ux-templates-js" */)
}

